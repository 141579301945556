<template>
  <div class="lineloss-query d-flex flex-column">
    <LinelossQuerySelector
      class="selector-container section-container"
      style="margin-bottom: 1rem"
      @selectItem="selectItemListener"
    ></LinelossQuerySelector>
    <el-table
      :data="linelossData"
      height="0"
      class="flex-grow-1 dark edit"
      stripe
      border
      size="small"
      row-key="TabIDstr"
      default-expand-all
      sty
      :row-class-name="
        (...nu) => {
          return  'dark text-white';
        }
      "
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
      <el-table-column label="设备名称" prop="SBName"></el-table-column>
      <el-table-column label="类型" prop="SBLeiXing"></el-table-column>
      <el-table-column label="编码" prop="SBBianMa"></el-table-column>
      <!-- <el-table-column
        label="起码(kWh)"
        prop="Indication_Begin"
      ></el-table-column>
      <el-table-column
        label="止码(kWh)"
        prop="Indication_End"
      ></el-table-column> -->
      <el-table-column label="入网电量(kWh)" prop="RuWangDianLiang"></el-table-column>
      <el-table-column label="售电量(kWh)" prop="ShouDianLiang"></el-table-column>
      <el-table-column label="线损电量(kWh)" prop="XianSunDianLiang"></el-table-column>
      <el-table-column label="线损(%)" prop="XianSunLv"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import LinelossQuerySelector from '@/components/project-line-loss/lineloss-query-selector.vue';
import { getLinelossTable } from '@/api/project/lineloss';
// import { getSelectCourtIds } from '@/utils/utils';

export default {
  name: 'lineloss-query',
  components: {
    LinelossQuerySelector,
  },
  data() {
    return {
      selectQuery: null,
      linelossData: [],
    };
  },
  created() {
    this.requestLinelossTable();
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    selectItemListener(selectIem) {
      this.selectQuery = selectIem;
      this.requestLinelossTable();
    },
    requestLinelossTable() {
      if (this.selectQuery == null) {
        return;
      }
      // const [start, end] = this.selectQuery.selectPeriod;
      getLinelossTable({
        // TaskIDstrS:
        //   this.selectQuery.id === this.selectProject.IdStr
        //     ? getSelectCourtIds(this.selectProject, 'pds').toString()
        //     : this.selectQuery.id,
        // SBIDstr: '',
        // Date_Begin: `${start} 00:00:00`,
        // Date_End: `${end} 23:59:59`,
        // Type: this.selectQuery.type,
        ProjectIDstr: this.selectProject.IdStr,
        Date: this.selectQuery,
      }).then((res) => {
        this.linelossData = this.setHasChildren(res);
        console.log('this.linelossData', this.linelossData);
      });
    },
    setHasChildren(list) {
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        if (item.List_C) {
          item.hasChildren = true;
          item.children = item.List_C;
          this.setHasChildren(item.List_C);
        } else {
          item.hasChildren = false;
        }
      }
      return list;
    },
  },
};
</script>
<style lang="less" scoped>
.el-table.dark th{
    color: #8696BF;
}
.el-table--border td, .el-table--border th, .el-table__body-wrapper
.el-table--border.is-scrolling-left~.el-table__fixed{
  border:none;
}
/deep/.el-table.dark th, .el-table.dark thead.is-group th {
  color:#8696BF;
  border:none;
  background-color: transparent;
}
// /deep/.el-table--border, .el-table--group{
//   border:none;
// }
/deep/.el-table--border.dark td, .el-table--border.dark th.is-leaf,
 .el-table--border.dark, .el-table--group.dark{
border:none;
}
/deep/.el-table--border td, .el-table--border th, .el-table__body-wrapper
 .el-table--border.is-scrolling-left~.el-table__fixed{
border:none;
background-color: #151A59;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped.dark td{
//  background: linear-gradient(15deg, #1C4596, #181D62);
 background-color: transparent;
}
/deep/.el-table.dark tr, .el-table.dark, .el-table tr.dark{
  // background: linear-gradient(75deg, #1C4596, #181D62);
  background: transparent;
}
/deep/.el-table--border.dark::after, .el-table--group.dark::after, .el-table.dark::before{
background: transparent;
}
/deep/.el-button--primary{
  color: #25C1FF;
  border:1px solid hsl(197, 100%, 57%);
  // background: linear-gradient(15deg, #1C4596, #181D62)
  background: #181D62;
}
.el-table tr{
  background-color: #fff;
}
</style>
