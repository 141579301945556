import { request } from '@/utils/request/request';
// 获取线损类型
export function getLinelossTypes(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_XS_SBCalss_List',
    method: 'GET',
    params,
  });
}
// 获取线损表格数据
// export function getLinelossTable(params) {
//   return request({
//     url: 'api/APPAPI_NEW/v1/LinLossAnaExport_ZiZhongDuan_FromDB',
//     method: 'GET',
//     params,
//   });
// }
// 获取线损表格数据
export function getLinelossTable(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_XS_Search',
    method: 'GET',
    params,
  });
}
