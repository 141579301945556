<template>
  <el-form class="lineloss-query-selector dark" :inline="true" size="small">
    <!-- <el-form-item label="类型:" class="light-label">
      <el-select v-model="form.type">
        <el-option label="日冻结数据" :value="0"></el-option>
        <el-option label="实时数据" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所属范围:" class="light-label">
      <el-cascader v-model="form.id" :options="[selectProjectWithRoom]"
       :props="props" clearable
       :show-all-levels="false"></el-cascader>
    </el-form-item> -->
    <el-form-item label="时间:" class="light-label">
      <!-- 开始时间结束时间 -->
      <!-- <el-date-picker
        v-model="form.selectPeriod"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker> -->
  <!-- 时间单选 -->
  <el-date-picker
        style="width: 150px"
        :clearable="true"
        v-model="stockDate"
        type="date"
        placeholder="选择日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        size="mini"
       :picker-options="pickerOptions"
      ></el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { getLinelossTypes } from '@/api/project/lineloss';
import { formatDate } from '@/utils/utils';
import clone from '@/utils/clone';

export default {
  name: 'lineloss-query-selector',
  data() {
    return {
      props: {
        emitPath: false,
        checkStrictly: true,
        value: 'IdStr',
        label: 'ModelName',
        children: 'Children',
      },
      formatDate,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      form: {
        type: 0,
        id: '',
        // selectPeriod: [
        //   formatDate(new Date(), 'yyyy-MM-01'),
        //   formatDate(new Date(), 'yyyy-MM-dd'),
        // ],
      },
      stockDate: this.getNowTime(),
      linelossTypes: [],
    };
  },
  created() {
    this.requestLinelossTypes();
  },
  mounted() {
    this.form.id = this.selectProject.IdStr;
    // this.$emit('selectItem', this.form);
    this.$emit('selectItem', this.stockDate);
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
    accountId() {
      return this.$store.getters.accountId;
    },
    selectProjectWithRoom() {
      const selectProjectWithRoom = clone(this.selectProject);
      this.getRoomWithoutChild(selectProjectWithRoom);
      return selectProjectWithRoom;
    },
  },
  methods: {
    onSubmit() {
      // this.$emit('selectItem', this.form);
      this.$emit('selectItem', this.stockDate);
    },
    getRoomWithoutChild(info) {
      if (info.oType === 'pds') {
        // eslint-disable-next-line no-param-reassign
        info.Children = null;
      } else if (info.Children) {
        for (let i = 0; i < info.Children.length; i += 1) {
          const child = info.Children[i];
          this.getRoomWithoutChild(child);
        }
      }
    },
    requestLinelossTypes() {
      getLinelossTypes({
        UIDstr: this.accountId,
        TaskIDstr: this.selectProject.IdStr,
      }).then((res) => {
        console.log('this.linelossTypes', res);
        this.linelossTypes = res;
      });
    },
    getNowTime() {
      const now = new Date();
      const year = now.getFullYear(); // 得到年份
      let month = now.getMonth(); // 得到月份
      let date = now.getDate(); // 得到日期
      month += 1;
      month = month.toString().padStart(2, '0');
      date = date.toString().padStart(2, '0');
      const defaultDate = `${year}-${month}-${date}`;
      console.log(defaultDate);
      return defaultDate;
    },
  },
};
</script>
