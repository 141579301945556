<template>
  <div class="project-line-loss flex-grow-1 d-flex flex-column">
    <Backup class="align-self-start"></Backup>
    <SectionFrame class="flex-grow-1">
      <SwitchTitle style="margin-bottom:1rem"
      :titleList="titleList" @selectTitle="selectTitleListener"></SwitchTitle>
      <template v-if="selectTitle">
        <RealtimeLineloss v-if="selectTitle.value===0" class="none-height flex-grow-1">
        </RealtimeLineloss>
        <LinelossQuery v-else class="none-height flex-grow-1"></LinelossQuery>
      </template>
    </SectionFrame>
  </div>
</template>
<script>
import ProjectMixin from '@/mixins/project-mixin';
import RealtimeLineloss from '@/components/project-line-loss/realtime-lineloss.vue';
import LinelossQuery from '@/components/project-line-loss/lineloss-query.vue';

export default {
  name: 'project-line-loss',
  mixins: [ProjectMixin],
  components: {
    RealtimeLineloss,
    LinelossQuery,
  },
  data() {
    return {
      titleList: [
        {
          title: '实时线损',
          value: 0,
        },
        {
          title: '线损查询',
          value: 1,
        },
      ],
    };
  },
};
</script>
