<template>
  <el-row class="realtime-data" type="flex">
    <!-- <el-col :span="5" class="d-flex flex-column">
      <SectionFrame class="none-height flex-grow-1 justify-content-around">
          <LinelossItem v-for="(lossInfo,index) in lossInfoList"
          :key="index" :linelossInfo="lossInfo"></LinelossItem>
      </SectionFrame>
    </el-col> -->
    <el-col :span="5" class="flex-column">
      <SectionFrame class="flex-grow-1 justify-content-around XS">
          <LinelossItem v-for="(lossInfo,index) in lossInfoList"
          :key="index" :linelossInfo="lossInfo"></LinelossItem>
      </SectionFrame>
    </el-col>
    <el-col :span="19" class="d-flex flex-column">
      <RoomPrimary :primaryType="1" :projectInfo="selectRoom" @child-event='parentEvent'>
      </RoomPrimary>
    </el-col>
  </el-row>
</template>
<script>
import ProjectMixin from '@/mixins/project-mixin';
import RoomPrimary from '@/components/svgs/room-primary.vue';
import LinelossItem from '@/components/project-line-loss/lineloss-item.vue';
import { getLinelossGeneralInfo } from '@/api/switchroom';
import { getSelectCourtIds } from '@/utils/utils';
import TransPng from '@/assets/images/project/line-loss/trans.png';
import OutlinePng from '@/assets/images/project/line-loss/outline.png';
import MeterBoxPng from '@/assets/images/project/line-loss/meter-box.png';
import MeterPng from '@/assets/images/project/line-loss/meter.png';

export default {
  name: 'realtime-data',
  mixins: [ProjectMixin],
  components: { RoomPrimary, LinelossItem },
  data() {
    return {
      selectTaskID: '',
      selectRoom: {
        IdStr: 'NjYwMjA0OTk5NkQzQzM4NA',
        ModelName: '银座好望角',
        oType: 'pds',
      },
      lossInfoList: [],
      imgList: [TransPng, OutlinePng, MeterBoxPng, MeterPng],
    };
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  created() {
    // this.requestLinelossGeneralInfo();
  },
  methods: {
    parentEvent(data) {
      this.selectTaskID = data;
      // console.log('传递的TaskId', this.selectTaskID);
      this.requestLinelossGeneralInfo();
    },
    requestLinelossGeneralInfo() {
      getLinelossGeneralInfo({
        UIDstr: this.accountId,
        // TaskIDstrS: this.getRoomIds().toString(),
        TaskIDstrS: this.selectTaskID,
      }).then((res) => {
        this.lossInfoList = res.map((item, index) => (
          { img: this.imgList[index % this.imgList.length], ...item }
        ));
      });
    },
    getRoomIds() {
      return getSelectCourtIds(this.selectProject);
    },
  },
};
</script>
<style scoped>
.XS {
 width: 80%;
 height: 60%;
}
</style>
