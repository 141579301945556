<template>
  <div
    class="lineloss-item full-bg d-flex"
    :style="{ backgroundImage: `url(${linelossInfo.img})` }"
  >
    <div
      class="
        content
        d-flex
        flex-column
        align-items-start
        justify-content-center
      "
    >
      <div class="title">{{ linelossInfo.Class }}</div>
      <div class="d-flex align-items-center" style="margin-top: 0.4rem">
        <div class="info-corn"></div>
        <span class="info-name">数量</span>
        <span class="info-value">{{ linelossInfo.Count }}</span>
        <span class="info-unit">个</span>
      </div>
      <div class="d-flex align-items-center" style="margin-top: 0.4rem">
        <div class="info-corn"></div>
        <span class="info-name">线损率:</span>
        <span class="info-value">{{ linelossInfo.LossRate.toFixed(2) }}</span>
        <span class="info-unit">%</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'lineloss-item',
  props: {
    linelossInfo: {
      type: Object,
      default() {
        return {
        };
      },
    },
  },
};
</script>
<style scoped>
.lineloss-item {
  min-height: 8rem;
}
.content {
  margin-left: 10rem;
}
.title {
  color: #fefefe;
  font-size: 1.2rem;
}
.info-name {
  margin-left: 0.6rem;
}
.info-name,
.info-unit {
  color: #778ed3;
}

.info-value {
  color: #25c1ff;
}
.info-corn {
  background-color: #25c1ff;
  min-width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
}
</style>
